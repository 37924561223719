<div data-navigation="container" class="i-top-header">
  <div class="wrapper top-section">
    <div class="hleft"><a class="logo selfLogo" [routerLink]="user.authStatus!='AUTH' ? ['/home'] : ['/dashboard']"><img alt="logo"
          src="../assets/images/logo.png"></a>
    </div>
    <div class="mnav_hb hide">
      <div class="hamburger"><span class="line"></span><span class="line"></span><span class="line"></span></div>
    </div>
    <nav class="hright dnav">
      <ul>
        <li *ngIf="user.authStatus!='AUTH'" routerLinkActive="active"><a (click)="login()">Login</a></li>
        <li *ngIf="user.authStatus!='AUTH'" routerLinkActive="active"><a (click)="register()">Register</a></li>
        <li *ngIf="user.authStatus=='AUTH'" routerLinkActive="active"><a routerLink="/dashboard">Dashboard</a></li>
        <li *ngIf="user.authStatus=='AUTH'" routerLinkActive="active"><a (click)="logout()">Logout</a></li>
      </ul>
    </nav>
  </div>
</div>
<router-outlet></router-outlet>
