<app-header></app-header>
<div class="login">
  <h2>Register</h2>
  <div class="field">
    <label for="username">Username:</label>
    <input type="text" id="username" [(ngModel)]="userData.username" name="username" required>
  </div>
  <div class="field">
    <label for="username">Email:</label>
    <input type="text" id="email" [(ngModel)]="userData.email" name="email" required>
  </div>
  <div class="field">
    <label for="password">Password:</label>
    <input type="password" id="password" [(ngModel)]="userData.password" name="password" required>
  </div>
  <button type="button" class="btn register-btn" (click)="register()">Register</button>
</div>
