<app-header></app-header>
<div class="site-section">
  <div>
    <mat-form-field appearance="fill">
      <input matInput (keyup)="applyFilter($event)" placeholder="Search">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="addRow()">Add Task</button>

    <table mat-table [dataSource]="data" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

      <!-- Position Column -->
      <ng-container matColumnDef="No.">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let index = index"> {{ currentPage * pageSize + index + 1 }} </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> title </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> description </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> dueDate </th>
        <td mat-cell *matCellDef="let element"> {{element.dueDate}} </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> priority </th>
        <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> status </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> category </th>
        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="editRow(element)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button (click)="showRow(element)">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteRow(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="right-align"></tr>

    </table>
    <mat-paginator [length]="totalElements"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 10, 20]"
                   (page)="currentPage = $event.pageIndex; updateSize($event.pageSize)">
    </mat-paginator>
  </div>
</div>
