<app-header></app-header>
<div class="main-content">
  <!-- content -->
  <div class="container-fluid content-top-gap">
    <div class="welcome-msg pt-3 pb-4">
      <h1>Hi <span class="text-dark">{{user.name}}</span>, Welcome back</h1>
    </div>

    <!-- User data -->
    <div class="statistics">
      <div class="row">
        <div class="col-xl-6">
          <div class="row">
            <div class="col-sm-6 statistics-grid" routerLink="/tasks">
              <div class="card card_border border-primary-top p-4">
                <i style="font-size: 3em; color : #343a40;" class="fa fa-tasks"> </i>
                <h3 class="text-dark number">Tasks</h3>
                <p class="stat-text">View Your Tasks</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- //content -->
</div>
<!-- main content end-->
