<app-header></app-header>
      <div class="welcome py-1">
        <div class="container py-xl-1 py-lg-1" id="services">
            <div class="row">
                <div class="col-lg-5 welcome-left">
                    <h3 class="title-w3ls mt-2 mb-3">Organize your life</h3>

                    <p class="mt-4 pr-lg-5">

                    </p>
                </div>
                <div class="col-lg-7 welcome-right text-center mt-lg-0 mt-5">
                    <div class="row">
                        <div class="col-sm-4 service-1-w3pvt serve-gd1">
                            <div class="serve-grid mt-4">
                                <span class="fa fa-book s1"></span>
                                <p class="mt-2">Learn </p>
                            </div>
                        </div>
                        <div class="col-sm-4 service-1-w3pvt serve-gd2">
                            <div class="serve-grid mt-4">
                                <span class="fa fa-briefcase s3"></span>
                                <p class="mt-2">Work </p>
                            </div>
                        </div>

                        <div class="col-sm-4 service-1-w3pvt serve-gd3">
                            <div class="serve-grid mt-4">
                                <span class="fa fa-bed s5"></span>
                                <p class="text-li mt-2">Rest </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
